import ReactDOM from 'react-dom/client';
import { call } from 'redux-saga/effects';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { Provider } from 'react-redux';
import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { select, takeEvery, put } from 'redux-saga/effects';
import { store } from '@aiware/shared/store';
import type { TRegistryComponent, AvailableWidgets } from '@aiware/js/interfaces';
import { AIWareThemeProvider, AIWareCacheProvider } from '@aiware/shared/theme';
import { getElement, getUniqueID } from '@aiware/js/function';
// import { registryLookup } from '../../../core/src/lib/registry';

export const WIDGETS_FEATURE_KEY = 'widgets';

interface IWidget<T> {
  widgetName: string;
  widgetConfig?: T;
  elementId: string;
  widgetId: string;
  onComplete?: IWidgetOnComplete;
}

export type IWidgetConfig = Record<string, unknown>;

enum WidgetsStatus {
  idle = 'idle',
  mounting = 'mounting',
  unmounting = 'unmounting',
  error = 'error',
  eventing = 'eventing',
}

interface WidgetState {
  value: IWidget<IWidgetConfig>[];
  status: WidgetsStatus;
}

interface State {
  [WIDGETS_FEATURE_KEY]: WidgetState;
}

const initialState: WidgetState = {
  value: [],
  status: WidgetsStatus.idle,
};

export const widgetsSelector = (state: State) => state[name].value;

export type IWidgetOnComplete = () => void;

export interface Widget<T, W> {
  name: W;
  elementId: string;
  config?: T;
}

const callWidgetOnComplete = (state: Array<IWidget<IWidgetConfig>>, name: string) => {
  const widget = state.find(widget => widget.widgetName === name);
  if (widget?.onComplete) {
    widget.onComplete();
  }
};

/**
 * Widgets Slice
 */
export const {
  name,
  reducer,
  actions: {
    mountWidget: mountWidgetAction,
    mountWidgetSuccess,
    mountWidgetError,
    unmountWidget: unmountWidgetAction,
    unmountWidgetSuccess,
    unmountWidgetFailure,
    triggerWidgetCallback,
    triggerWidgetCallbackSuccess,
  },
} = createSlice({
  name: WIDGETS_FEATURE_KEY,
  initialState,
  reducers: {
    mountWidget: (state, action: PayloadAction<IWidget<IWidgetConfig>>) => {
      // add widget to store when mounted
      state.value.push(action.payload);
      state.status = WidgetsStatus.mounting;
    },
    mountWidgetSuccess: state => {
      state.status = WidgetsStatus.idle;
    },
    mountWidgetError: state => {
      state.status = WidgetsStatus.error;
    },
    triggerWidgetCallback: (state, _action: PayloadAction<string>) => {
      // trigger the call to the mountWidget callback function
      state.status = WidgetsStatus.eventing;
    },
    triggerWidgetCallbackSuccess: state => {
      state.status = WidgetsStatus.idle;
    },
    unmountWidget: (state, _action: PayloadAction<string>) => {
      // the dom element is removed first
      state.status = WidgetsStatus.unmounting;
    },
    unmountWidgetSuccess: (state, action: PayloadAction<string>) => {
      // remove widget from store when unmount is finished
      state.value = state.value.filter(item => item.widgetId !== action.payload);
      state.status = WidgetsStatus.idle;
    },
    unmountWidgetFailure: state => {
      // removing dom element failed
      state.status = WidgetsStatus.error;
    },
  },
});

/**
 * Listens for widget mount/unmounts
 */
const mountWidgetListenerFactory = (registryLookup: (key: string) => Promise<unknown>) => {
  return function* mountWidgetListenerSaga() {
    let root: ReactDOM.Root;
    const rootHash: { [key: string]: ReactDOM.Root } = {};
    yield takeEvery(mountWidgetAction.type, function* (action: PayloadAction<IWidget<IWidgetConfig>>) {
      try {
        const { widgetConfig = {}, widgetName, elementId } = action?.payload ?? {};
        const cmp: TRegistryComponent<unknown> = yield call(registryLookup, widgetName);
        const Component: TRegistryComponent<unknown> = cmp || (() => <div>Component Not Found.</div>);
        // todo pass base story props (or wrap it here?)
        const el = getElement(elementId, widgetName);
        el.classList.add('aiware-el');
        if (!rootHash[elementId]) {
          root = ReactDOM.createRoot(el);
          rootHash[elementId] = root;
        } else {
          root = rootHash[elementId]!;
        }
        root.render(
          <AIWareCacheProvider>
            <AIWareThemeProvider>
              <Provider store={store}>
                <Component {...widgetConfig} />
              </Provider>
            </AIWareThemeProvider>
          </AIWareCacheProvider>
        );
        yield put(mountWidgetSuccess());
      } catch (e) {
        yield put(mountWidgetError());
      }
    });

    // if widgets dispatch an action with the pattern {widget_name}/booted then trigger the widget callback
    yield takeEvery(
      (action: Action) => /\/booted$/.test(action.type),
      function* (action: PayloadAction) {
        const [widgeName] = action.type.split('/');
        yield put(triggerWidgetCallback(widgeName!));
      }
    );

    yield takeEvery(unmountWidgetAction.type, function* (action: PayloadAction<string>) {
      try {
        const elementId: string = yield select((state: State) => {
          const widgets = widgetsSelector(state);
          return widgets.find(item => item.widgetId === action.payload)?.elementId || '';
        });
        setTimeout(() => {
          rootHash[elementId] && rootHash[elementId]!.unmount();
          delete rootHash[elementId];
        });
        yield put(unmountWidgetSuccess(action.payload));
      } catch (e) {
        yield put(unmountWidgetFailure());
      }
    });

    yield takeEvery(triggerWidgetCallback('').type, function* (action: PayloadAction<string>) {
      const widgets: IWidget<IWidgetConfig>[] = yield select(widgetsSelector);
      callWidgetOnComplete(widgets, action.payload);
      yield put(triggerWidgetCallbackSuccess());
    });
  };
};

export function getWidgetsModule(registryLookup: (key: string) => Promise<unknown>): ISagaModule<unknown> {
  return {
    id: name,
    reducerMap: {
      [name]: reducer,
    },
    sagas: [mountWidgetListenerFactory(registryLookup)],
    initialActions: [],
  };
}

export type TMountWidget<T extends IWidgetConfig, W extends string = AvailableWidgets> = (
  props: Widget<T, W>,
  callback?: IWidgetOnComplete
) => string | Promise<string>;
/**
 * Mounts the widget
 * @param props
 * @param callback - optional callback function that will be called when the widget mounting is complete
 */
export const mountWidget = <T extends IWidgetConfig, W extends string = AvailableWidgets>(
  props: Widget<T, W>,
  callback: IWidgetOnComplete
) => {
  const widgetId = getUniqueID();
  const { name, elementId, config } = props;
  if (!name || !elementId || !config) {
    console.error(
      '[aiwarejs-error]: `mountWidget` function should include all the following params: name, elementId, config'
    );
  }
  store.dispatch(
    mountWidgetAction({
      elementId,
      widgetName: name,
      widgetConfig: config,
      widgetId,
      onComplete: callback,
    })
  );

  return widgetId;
};

export const unmountWidget = (widgetId: string) => {
  store.dispatch(unmountWidgetAction(widgetId));
  return true;
};
