import { IInitialState, IState } from './interfaces';
import { namespace } from './slice';

export const sessionTokenSelector = (state: IState) => state[namespace]?.sessionToken;

export const authSelector = (state: { [namespace]: IInitialState }) => state?.[namespace] || {};

export const userSelector = (state: IState) => state[namespace]?.user;

export const selectUserMyRights = (state: IState) => state[namespace]?.user?.myRights || [];

export const selectHasUserCreateRootFolderPermission = (state: IState): boolean =>
  (state[namespace]?.user?.myRights || []).some((right: string) =>
    // see the scope of create a root folder mutation
    // https://github.com/veritone/aiware-core/blob/79d7b5a6f1745b5814fda076313fc3c9d94f5a88/services/api/core-graphql-server/schema/schema.graphql#L8014
    ['collections.collections.create', 'cms.media.create', 'veritone.superadmin'].includes(right)
  );

export const selectUserCanAccessResourceCenter = (state: IState): boolean =>
  (state[namespace]?.user?.myRights || []).some((right: string) => {
    const adminAccess = ['admin.access'].includes(right);

    const developerAccess = [
      'developer.engine.update',
      'developer.engine.create',
      'developer.engine.delete',
      'developer.engine.disable',
      'developer.engine.enable',
    ].includes(right);

    const dockerAccess = ['developer.docker.admin'].includes(right);

    // This check with admin access is here as a placeholder - eventually we may want to just check admin access if queries don't require developer access
    if (adminAccess && (developerAccess || dockerAccess)) {
      return true;
    }
    if (developerAccess) {
      return true;
    }
    if (dockerAccess) {
      return true;
    }
    return false;
  });

export const selectUserCanCreatePackage = (state: IState): boolean => {
  // right now create package has same permissions as access resource center - to fix code duplication just commenting this out
  // if permissions change - we can add them below
  return selectUserCanAccessResourceCenter(state);

  // return (state[namespace]?.user?.myRights || []).some((right: string) => {
  //   const adminAccess = ['admin.access'].includes(right);
  //
  //   const developerAccess = [
  //     'developer.engine.update',
  //     'developer.engine.create',
  //     'developer.engine.delete',
  //     'developer.engine.disable',
  //     'developer.engine.enable',
  //   ].includes(right);
  //
  //   const dockerAccess = ['developer.docker.admin'].includes(right);
  //
  //   // This check with admin access is here as a placeholder - eventually we may want to just check admin access if queries don't require developer access
  //   if (adminAccess && (developerAccess || dockerAccess)) {
  //     return true;
  //   }
  //   if (developerAccess) {
  //     return true;
  //   }
  //   if (dockerAccess) {
  //     return true;
  //   }
  //   return false;
  // });
};
export const selectIsSuperAdmin = (state: IState): boolean =>
  (state[namespace]?.user?.myRights || []).some((right: string) => {
    return ['superadmin', 'veritone.superadmin'].includes(right);
  });

export const selectOrgGuid = (state: IState) => state[namespace]?.user?.organization?.guid || '';

export const selectOrgId = (state: IState) => state[namespace]?.user?.organization?.organizationId;

export const authErrorMessage = (state: IState) => state[namespace]?.errorMessage;

export const selectIsOLPEnabled = (state: IState) =>
  state[namespace]?.user?.organization?.kvp?.features?.['enableRBACFeature'] === 'enabled';

export const selectUserOrgEdgeVersion = (state: IState) =>
  state[namespace]?.user?.organization?.kvp?.features?.['edgeVersion'];

export const selectUserOrgHideCmsFromAppList = (state: IState) =>
  state[namespace]?.user?.organization?.kvp?.features?.['hideCMSFromAppList'] === true;

export const selectUserOrgHideAdminFromAppList = (state: IState) =>
  state[namespace]?.user?.organization?.kvp?.features?.['hideAdminAppFromAppSwitcher'] === true;

export const authContextSelector = (state: IState) => state[namespace]?.authContext;

export const authStatusSelector = (state: IState) => state[namespace]?.status;

export const userActivityStatusSelector = (state: IState) => state[namespace]?.userIsActive;

export const preferredLanguageSelector = (state: IState) =>
  state[namespace]?.user?.preferredLanguage
    ? state[namespace]?.user?.preferredLanguage
    : // english is default language
      'en';

export const selectIsUserAdmin = (state: IState) => state[namespace]?.user?.roles?.includes('superadmin');

export const oAuthTokenSelector = (state: IState) => state?.[namespace]?.oAuthToken;

export const oAuthStatusSelector = (state: IState) => state?.[namespace]?.oAuthStatus;

export const initStatusSelector = (state: IState) => state?.[namespace]?.initStatus;

export const userRecentApplicationsSelector = (state: IState) =>
  state[namespace]?.user?.recentApplications ? JSON.parse(state[namespace]?.user?.recentApplications) : [];

export const selectUserWallpapers = (state: IState) =>
  (state[namespace]?.user?.userSettings || []).filter(
    (setting: { key: string; value: string }) =>
      setting.key.includes('wallpaperCustom_') || setting.key.includes('wallpaperCurrent')
  );

export const selectUserOrganizationBusinessUnit = (state: IState) =>
  state[namespace]?.user?.organization?.businessUnit;

export const impersonationStateSelector = (state: IState) => state[namespace]?.impersonationState;

export const requestPasswordResetStateSelector = (state: IState) =>
  state[namespace]?.requestPasswordResetState;
