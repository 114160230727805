/* eslint-disable @nx/enforce-module-boundaries */
import ReactDOM from 'react-dom/client';
import {
  IInitConfig,
  ISdkEventManager,
  TRegistry,
  TRegistryKeys,
  TSdkBootstrapOptions,
  TRegistryPromise,
  TRegistryLookup,
} from '@aiware/js/interfaces';
import { store } from '@aiware/shared/store';
import { TInitCallback } from './types';
import { unstable_ClassNameGenerator } from '@mui/material/className';

const isAPromise = (obj: unknown): obj is Promise<unknown> => {
  return obj !== null && typeof obj === 'object' && obj.constructor === Promise;
};

export type { TInitCallback };

type TBootStrapRegistryPromise = Promise<{ registry: TRegistry }>;

type TBootstrapRegistryLookupPromise = Promise<{ registryLookup: TRegistryLookup }>;

export const bootstrapSdk = async <R extends string = TRegistryKeys>(
  bootstrapOptions?: TSdkBootstrapOptions<R>
) => {
  let registryRecords: TRegistry;
  let lookupFunction: (key: string) => TRegistryPromise;
  if (bootstrapOptions?.configureClasses) {
    // configure the mui class names, then resolve the registry promises
    // (since we cannot import from the registry here without creating circular deps)
    unstable_ClassNameGenerator.configure(componentName => componentName.replace(/^Mui/, 'Sdk-Mui'));
    const { registry } = await (bootstrapOptions.registry as TBootStrapRegistryPromise);
    const { registryLookup } = await (bootstrapOptions.registryLookup as TBootstrapRegistryLookupPromise);
    registryRecords = registry as TRegistry;
    lookupFunction = registryLookup as (key: string) => TRegistryPromise;
  } else if (isAPromise(bootstrapOptions?.registry) && isAPromise(bootstrapOptions?.registryLookup)) {
    // the registry items are promises
    const { registry } = await (bootstrapOptions!.registry as TBootStrapRegistryPromise);
    const { registryLookup } = await (bootstrapOptions!.registryLookup as TBootstrapRegistryLookupPromise);
    registryRecords = registry as TRegistry;
    lookupFunction = registryLookup as (key: string) => TRegistryPromise;
  } else {
    // the registry items will be explicitly passed in if needed
    registryRecords = (bootstrapOptions?.registry || {}) as TRegistry;
    lookupFunction = (bootstrapOptions?.registryLookup || Promise.resolve(null)) as TRegistryLookup;
  }
  const { validateConfig } = await import('./validateConfig');
  const {
    getInitConfig,
    getConfigModule,
    getAuthModule,
    handleImplicitRedirect,
    getExportEngineAssetsModule,
    getUIStateModule,
  } = await import('@aiware/shared/redux');
  const { getPanelsModule } = await import('@aiware/js/panel');
  const { getElement } = await import('@aiware/js/function');
  const { getPermissionsModule } = await import('@aiware/shared/permissions');
  const { getWidgetsModule, mountWidget, unmountWidget } = await import('../widget');
  const { getDataModule } = await import('./data.redux');
  const { sdkEventManager } = await import('@aiware/js/sdk-event-manager');
  const { handleEvents } = await import('./handleEvents');
  const { getSnackbarModule } = await import('./components/CustomSnackbar');
  const { default: Preloader } = await import('./components/Preloader');
  const { attachSdkToWindow } = await import('./window');

  const aiwareEvents = handleEvents(sdkEventManager);

  const state: { widgets: unknown } = store.getState() as { widgets: unknown };
  if (!state?.widgets) {
    store.addModules([
      getWidgetsModule(lookupFunction),
      getPanelsModule(registryRecords, lookupFunction),
      getDataModule(),
      getConfigModule(),
      getAuthModule(sdkEventManager as ISdkEventManager),
      getPermissionsModule(),
      getSnackbarModule(),
      getExportEngineAssetsModule(),
      getUIStateModule(),
    ]);

    /**
     * Creates Root aiWARE Element if it doesn't exists
     */
    const root = getElement('aiWARE');
    root.classList.add('aiware-el');
    const reactDOMroot = ReactDOM.createRoot(root);
    if (window.name === '_auth') {
      // if this is an OAuth redirect window, deal with the OAuth response but
      // don't render the app.
      handleImplicitRedirect(window.location.hash, window.opener);
    } else {
      /**
       * Render modal renderer
       */
      reactDOMroot.render(<Preloader store={store} />);
    }
  }

  const init = (initConfig: IInitConfig, onComplete?: TInitCallback) => {
    const initState = store.getState() as { configs: { status: string } };
    if (initState.configs.status !== 'idle') {
      return;
    }
    validateConfig(initConfig)
      .then(validatedConfig => {
        if (validatedConfig) {
          store.dispatch(getInitConfig({ ...validatedConfig, onComplete }));
        } else {
          console.error('@aiware/js initialization failed. Please pass valid configuration object.');
        }
      })
      .catch(() => {
        console.error('@aiware/js initialization failed. Please pass valid configuration object.');
      });
  };

  attachSdkToWindow<R>({
    init,
    store,
    aiwareEvents,
    bootstrapOptions: {
      registry: registryRecords,
      registryLookup: lookupFunction,
    },
  });

  return {
    store,
    aiwareEvents,
    init,
    mountWidget,
    unmountWidget,
  };
};
