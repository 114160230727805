import { ISagaModule } from 'redux-dynamic-modules-saga';
import { ISdkEventManager } from '@aiware/js/interfaces';
import authReducer, { namespace, fetchUserRequest } from './slice';
import authSagaFactory from './saga';

// actions
export {
  fetchUserRequest,
  fetchUserSuccess,
  fetchUserError,
  extendTokenRequest,
  refreshTokenRequest,
  refreshTokenSuccess,
  setTokenExpiration,
  logoutRequest,
  logoutSuccess,
  logoutError,
  updateTokenRequest,
  updateTokenSuccess,
  updateTokenError,
  updateUserLanguageStart,
  resetPasswordRequest,
  updateUserRecentApplicationsStart,
  impersonateUserStart,
  impersonateUserError,
  requestPasswordResetStart,
} from './slice';

// selectors
export {
  authSelector,
  sessionTokenSelector,
  userSelector,
  preferredLanguageSelector,
  authErrorMessage,
  authContextSelector,
  authStatusSelector,
  selectOrgGuid,
  selectOrgId,
  selectIsUserAdmin,
  selectIsOLPEnabled,
  selectUserOrgEdgeVersion,
  userRecentApplicationsSelector,
  selectUserMyRights,
  selectHasUserCreateRootFolderPermission,
  selectUserOrgHideCmsFromAppList,
  selectUserOrgHideAdminFromAppList,
  selectIsSuperAdmin,
  selectUserWallpapers,
  selectUserCanAccessResourceCenter,
  selectUserCanCreatePackage,
  selectUserOrganizationBusinessUnit,
} from './selector';

// interfaces

export { AuthContext, Status } from './interfaces';
export type { IState as AuthState } from './interfaces';

export {
  login,
  logout,
  handleImplicitRedirect,
  stashTokenInSession,
  fetchTokenFromSession,
  SESSION_TOKEN_PREVENT_STASH_VALUE,
} from './oAuth';

export function getAuthModule(sdkEventManager?: ISdkEventManager): ISagaModule<typeof authReducer> {
  return {
    id: namespace,
    reducerMap: {
      [namespace]: authReducer,
    },
    sagas: [authSagaFactory(sdkEventManager)],
    initialActions: [fetchUserRequest()],
  };
}
