/**
 * It's a self contained react-redux module
 * It's a 'feature'
 * It could be shown in Widget, Panel, or Modal.
 */

import { type ReactElement, type FunctionComponent } from 'react';
import { AiwareFrameLink } from '@aiware/js/frame-link';

export interface IMicroFrontend<MicroFrontendConfig> {
  // unique name, we will get the component by this name
  name: string;
  // microFrontend config
  config: MicroFrontendConfig;

  // we don't add the component here, because we want this to be serializable
  // component:  Component
}

/**
 * Widget renders microFrontend directly into DOM
 */
export interface IWidget<MicroFrontendConfig> {
  elementId: string;
  microFrontend: IMicroFrontend<MicroFrontendConfig>;
}

/**
 * Slide in panel that renders a microFrontend
 * It could have close button, and predefined actions
 */
export interface IPanel<MicroFrontendConfig, PanelConfig> {
  panelId: string;
  microFrontend: IMicroFrontend<MicroFrontendConfig>;
  panelConfig: PanelConfig;
}

/**
 * Modal / Dialog That Provides predefined action buttons
 * And renders a MicroFrontend
 */
export interface IModal<MicroFrontendConfig> {
  microFrontend: IMicroFrontend<MicroFrontendConfig>;
}

/**
 * Mounts the widget
 */
export interface IMountWidget<MicroFrontendConfig> {
  /**
   * returns element id
   *
   * @param microFrontend
   * @param elementId
   */
  (microFrontend: IMicroFrontend<MicroFrontendConfig>, elementId: string): string;
}

/**
 * Unmounts the widget
 */
export interface IUnmountWidget {
  /**
   * returns true if widget was successfully unmounted
   * @param elementId
   */
  (elementId: string): string;
}

export interface IShowPanelProps<MicroFrontendConfig, PanelConfig> {
  panelId?: string;
  microFrontend: IMicroFrontend<MicroFrontendConfig>;
  panelConfig: PanelConfig;
}

/**
 * Shows the panel
 */
export interface IShowPanel<MicroFrontendConfig, PanelConfig> {
  /**
   * returns panel id
   *
   * MicroFrontend's configuration
   *
   * Panel's Configuration
   * @param props
   */
  (props: IShowPanelProps<MicroFrontendConfig, PanelConfig>): string;
}

/**
 * Hides the panel
 */
export interface IHidePanel {
  /**
   *
   * @param panelId
   */
  (panelId: string): boolean;
}

/**
 * Hides all panels
 */
export interface IHideAllPanels {
  /**
   *
   * @param force
   * Here if we'd like to add are you sure pattern
   */
  (force: boolean): boolean;
}

/**
 * Shows the modal
 */
export interface IShowModal<MicroFrontendConfig> {
  /**
   * returns modal id
   *
   * @param microFrontend
   */
  (microFrontend: IMicroFrontend<MicroFrontendConfig>): string;
}

/**
 * Hides the modal
 */
export interface IHideModal {
  /**
   * returns if modal successfully hid
   * We may use it fo are you sure popups.
   *
   * @param modalId
   * Modal id that we'd like to hide
   */
  (modalId: string): boolean;
}

export interface IPanelActionBaseAction {
  type: string;
  payload?: unknown;
}

export interface IPanelActionBase {
  // standard redux action
  action: IPanelActionBaseAction;
  onClick?: (e: unknown) => void;
}

/**
 * Panel header & footer buttons
 */
export interface IPanelActionButton extends IPanelActionBase {
  // icon of button
  icon?: ReactElement;
  // icon link of button
  iconUrl?: string;
  // triggers callback to caller
  submit?: boolean;
  // label of the button
  label?: string;
  // material ui button variant
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'large' | 'medium' | 'small';
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  url?: string;
}

export interface IPanelActionIconButton extends IPanelActionBase {
  // icon of button
  icon?: ReactElement;
  // icon link of button
  iconUrl?: string;
  // triggers callback to caller
  submit?: boolean;
  label?: string;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'large' | 'medium' | 'small';
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
}

export type IPanelAction = IPanelActionButton | IPanelActionIconButton;

// limit number of panel actions
type ArrayOfOneActions = [IPanelAction];
type ArrayOfTwoActions = [IPanelAction, IPanelAction];
type ArrayOfThreeActions = [IPanelAction, IPanelAction, IPanelAction];

/**
 * Panel Header Configurations
 */
export interface IPanelHeaderConfig {
  // title of the panel
  title: string | ReactElement;
  // show divider between the content
  divider?: boolean;
  // Action Buttons. Normal button or Icon Button
  actions?: ArrayOfOneActions | ArrayOfTwoActions | ArrayOfThreeActions;
}

/**
 * Panel Footer Configurations
 */
export interface IPanelFooterConfig {
  // show divider between the content
  divider?: boolean;
  // whether or not show the footer logo
  logo?: boolean;
  // panel actions, 3 max
  actions?: ArrayOfOneActions | ArrayOfTwoActions | ArrayOfThreeActions;
}

/**
 * Panel configuration
 */

export interface IDefaultAppbarTabPanelConfig {
  /*
   * Data id of the panel
   * @default 'panelId'
   */
  dataId: string;

  /*
   * Tab id of the panel
   */
  tabId: string;

  /*
   * Tells you if the panel is rendered
   */
  panelRendered: boolean;

  /*
   * A function to call to hide the panel. It already has context of what the `panelId`.
   * @example `hidePanel()`
   */
  hidePanel: () => void;
}

export interface ITabConfig {
  label: string | ReactElement;
  icon?: string;
  iconComponent?: ReactElement;
  id: TabId;
  onClick?: (event: unknown) => void;
  action?: {
    type: string;
    payload: unknown;
  };
  role?: string;
}

export interface IVeritoneAppbarPanelConfig {
  type: string;
  // panel id
  panelId: string;
  // children
  children?: unknown;
  // optional header configuration
  header?: IPanelHeaderConfig;
  // optional footer configuration
  footer?: IPanelFooterConfig;
  // parent panel
  parent?: string;
  // sets if this component can stack, slide etc.
  mode?: CreateModes;
  // dismiss other panels on open but this
  dismissOthers?: boolean;
  // height of the panel
  height?: string | number;
  // minimum width of the panel
  minWidth?: string | number;
  // top margin of the panel
  marginTop?: string | number;
  // start margin (when others present)
  marginStart?: string | number;
  /**
   * Z Index applied to the AppBarTabPanel component
   * @deprecated
   */
  zIndex?: number;
  // automatically generated zIndex to handle parent and child panel nesting
  autoZIndex?: number;
  // adds component padding
  padding?: number;
  // show the panel
  show?: boolean;
  // width
  width?: number;
  // with tab
  withTab?: boolean;
  // with child
  withChild?: boolean;
  // tabs
  tabs?: ITabConfig[];
  // role
  userRoles?: string[];
  // parent panel id
  parentPanelId?: string;
  // sibling panel id
  siblingPanelId?: string;
  // ancestor center panel id
  ancestorCenterPanelId?: string;
  // dimmed by number of panels
  dimmed?: number;
  // dim parent panel or all parent panels
  dimmedStatus?: 'dimParent' | 'dimAllParents';
  blockParent?: boolean;
  size?: 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small';
  fullScreen?: boolean;
  borderBottom?: boolean;
  microFrontend?: {
    config?: {
      requestId?: string;
      frameLink?: AiwareFrameLink;
    };
  };
}

export interface ISimplePanelConfig {
  type: string;
  // panel id
  panelId: string;
  // children
  children?: unknown;
  // optional header configuration
  header?: IPanelHeaderConfig;
  // optional footer configuration
  footer?: IPanelFooterConfig;
  // parent panel
  parent?: string;
  // sets if this component can stack, slide etc.
  mode?: CreateModes;
  // dismiss other panels on open but this
  dismissOthers?: boolean;
  // height of the panel
  height?: string | number;
  // minimum width of the panel
  minWidth?: string | number;
  // top margin of the panel
  marginTop?: string | number;
  // start margin (when others present)
  marginStart?: string | number;
  // zIndex
  zIndex?: number;
  // adds component padding
  padding?: number;
  // show the panel
  show?: boolean;
  // width
  width?: number;
}

export interface ISelfClosingPanelConfig extends IVeritoneAppbarPanelConfig {
  // perform unmountPanel when true, perform hidePanel otherwise
  disableHide?: boolean;
}

export interface IVeritonePanelConfig {
  type: 'APP_BAR_PANEL';
  // panel name
  name?: string;
  // tab Id
  tabId?: string;
  // dataId
  dataId?: string;
}

export enum NotificationFlag {
  unread = 'unread',
  read = 'read',
  actionPending = 'actionPending',
  actionDone = 'actionDone',
  seen = 'seen',
  unseen = 'unseen',
  starred = 'starred',
}

export interface INotificationDetail {
  id: string;
  appId: string;
  appName?: string;
  icon?: string;
  title?: string;
  timestamp: number;
  body: string; // string, json, html
  contentType: string; // Hint what/how the body is encode
  flags: NotificationFlag[];
  createdDateTime: string;
  readDateTime?: string;
  updatedDateTime?: string;
  actions?: IPanelActionButton[];
  mailboxId?: string;
  timeValue?: number; // diff time from notification create date time to now
  eventType?: string;
  eventNames?: string[];
}

export interface IAvatar {
  name: string;
  src: string;
}

export interface ICollectionCardItem {
  title: string;
  articles: number;
  authors: string;
  des: string;
  avatars: IAvatar[];
}

/**
 * Declare where panel going to be positioned, and
 * What could happen to parent panels
 */
export enum CreateModes {
  // adds new panel to start without interfering with others
  ADD,
  // can slide and stack towards to the end
  STACK,
  // positions it self to the end of the parent component
  SLIDE_MORE,
  // creates an overlay over parent component, and positions panel to start
  OVERLAY_OVER_PARENT,
}

export interface IMicroFrontendConfig {
  tabId?: TabId;
}

// Tab interface

export enum TabId {
  APP_PANEL = 'APP_PANEL',
  PERSONAL_PANEL = 'PERSONAL_PANEL',
  ORGANIZATION_PANEL = 'ORGANIZATION_PANEL',
  MARKET_PANEL = 'MARKET_PANEL',
  SETTING_PREFERENCE_PANEL = 'SETTING_PREFERENCE_PANEL',
  ACTIVITY_PANEL = 'ACTIVITY_PANEL',
  PERFORMANCE_MONITOR = 'PERFORMANCE_MONITOR',
  CMS_SHORTCUT = 'CMS_SHORTCUT',
  FLOW_CENTER = 'FLOW_CENTER',
  ADMIN_SHORTCUT = 'ADMIN_CENTER',
  DATA_CENTER = 'DATA_CENTER',
  RESOURCE_CENTER = 'RESOURCE_CENTER',
  ENGINE_CENTER = 'ENGINE_CENTER',
  LIBRARY_CENTER = 'LIBRARY_CENTER',
  MARKETPLACE_CENTER = 'MARKETPLACE_CENTER',
  PROCESSING_CENTER = 'PROCESSING_CENTER',
  PROCESSING_CENTER_DAG_BUILDER = 'PROCESSING_CENTER_DAG_BUILDER',
  INSTANCE_ADMIN_CENTER_BROWSE = 'INSTANCE_ADMIN_CENTER_BROWSE',
}

/**
 * @aiware/js initialization config
 */
export interface IInitConfig {
  // application id (optional)
  applicationId?: string;

  // base graphql url (required)
  baseUrl: string;

  // authentication token
  authToken?: string;

  // optional redirect url for after logout
  logoutRedirectUrl?: string;

  // optional flag to hide password reset for user
  hidePasswordReset?: boolean;

  // enable beta features
  betaFeatures?: boolean;

  // enable admin link on the dock
  adminShortcut?: boolean;

  // enable cms link on the dock
  cmsShortcut?: boolean;

  // minutes until session expires
  sessionTimeout?: number;

  // callback after init, and return the token if handleAuth is true
  onComplete?: (error?: unknown, authToken?: string) => void;

  // application knowledge base path
  knowledgeBaseUrl?: string;

  // DEPRECATED REDUNDANCY - until we are ready for breaking changes / sdk v3 - allow apps to continue specifying this config option
  knowledgeBaseURL?: string;

  // application developer documentation path
  docsURL?: string;

  // move baseline css from the body tag to application scope
  scopedStyles?: boolean;

  // enable oauth usage in sdk
  handleAuth?: boolean;

  // when handleAuth is true, the redirect uri to use for oauth
  oAuthRedirectUri?: string;

  // turn off the default app unavailable modal
  disableAppUnavailableModal?: boolean;
}

export interface IOrganization {
  imageUrl?: string;
  name?: string;
  guid?: string;
  organizationName?: string;
  organizationId?: number;
  id?: number;
  organizationGuid?: string;
  status?: 'active' | 'deleted';
  kvp?: {
    features?: {
      [key: string]: any;
    };
  };
  businessUnit?: string;
}

export interface IGenericState {
  [key: string]: unknown;
}

export interface IGenericAction {
  type: string;
  payload: unknown;
}

export interface IMailbox {
  id: string;
  name: string;
  paused: boolean;
  totalCount: number;
  unseenCount: number;
  eventFilter: {
    applicationId: string;
    eventNames?: string[];
  };
  application?: {
    applicationId: string;
    icon?: string;
    name: string;
    description: string;
  };
  notifications?: INotificationDetail[];
}

export enum ELoadingStatus {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  failure = 'failure',
  pending = 'pending',
}

export type LoadingStatus = 'idle' | 'loading' | 'success' | 'failure' | 'pending';

export interface ICountObj {
  mailboxId: string;
  notificationMailboxes: {
    id: string;
    unseenCount: number;
  }[];
}

export enum EUserStatus {
  active = 'active',
  suspended = 'suspended',
  deleted = 'deleted',
  deactivated = 'deactivated',
  inactive = 'inactive',
}

export interface IUser {
  token?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  imageUrl?: string | null;
  preferredLanguage?: string;
  userSettings?: { key: string; value: string }[];
  email?: string;
  lastUpdated?: string;
  roles?: string[];
  avatarHistory?: string[];
  userId?: string;
  id?: string;
  organization?: IOrganization;
  signedImageUrl?: string;
  tokenExpiration?: string;
  recentApplications?: string;
  lastLoginDateTime?: string;
  status?: EUserStatus;
  organizationGuids?: string[];
  myRights?: string[];
  scimConnectId?: string;
  impersonatorId?: string;
  impersonatorUserName?: string;
}

export enum AvailableWidgets {
  SAMPLE_APP_BAR = 'SAMPLE_APP_BAR',
  APP_BAR = 'APP_BAR',
  HEADER_ACTIONS = 'HEADER_ACTIONS',
  IMPERSONATION_BANNER = 'IMPERSONATION_BANNER',
  FLOW_CENTER = 'FLOW_CENTER',
  FLOW_WIDGET = 'FLOW_WIDGET',
  ENGINE_CENTER_BROWSE = 'ENGINE_CENTER_BROWSE',
  MACHINE_BOX_BROWSE = 'MACHINE_BOX_BROWSE',
  DEVELOPER_OVERVIEW = 'DEVELOPER_OVERVIEW',
  APP_CENTER_BROWSE = 'APP_CENTER_BROWSE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  AUDIT_LOGS_WIDGET = 'AUDIT_LOGS_WIDGET',
}

export enum AvailablePanels {
  SIMPLE_PANEL = 'SIMPLE_PANEL',
  APP_BAR_PANEL_TEMPLATE = 'APP_BAR_PANEL_TEMPLATE',
  APP_BAR_TAB_PANEL_TEMPLATE = 'APP_BAR_TAB_PANEL_TEMPLATE',
  TAB_TEMPLATE = 'TAB_TEMPLATE',
}

export enum AvailableComponents {
  ADD_APPLICATIONS = 'ADD_APPLICATIONS',
  ADD_APPLICATION_USERS_PANEL = 'ADD_APPLICATION_USERS_PANEL',
  ADMIN_CENTER = 'ADMIN_CENTER',
  ADMIN_CENTER_ADD_MEMBER_PANEL = 'ADMIN_CENTER_ADD_MEMBER_PANEL',
  ADMIN_CENTER_APPROVAL_REQUESTS_PANEL = 'ADMIN_CENTER_APPROVAL_REQUESTS_PANEL',
  ADMIN_CENTER_PACKAGES_RESOURCES_PANEL = 'ADMIN_CENTER_PACKAGES_RESOURCES_PANEL',
  ADMIN_CENTER_PACKAGES_PANEL = 'ADMIN_CENTER_PACKAGES_PANEL',
  ADMIN_CENTER_CUSTOM_FIELDS_FORM = 'ADMIN_CENTER_CUSTOM_FIELDS_FORM',
  ADMIN_CENTER_CUSTOM_LOGIN_PANEL = 'ADMIN_CENTER_CUSTOM_LOGIN_PANEL',
  ADMIN_CENTER_CUSTOM_REGISTRATION_PANEL = 'ADMIN_CENTER_CUSTOM_REGISTRATION_PANEL',
  ADMIN_CENTER_GROUP_DETAILS_PANEL = 'ADMIN_CENTER_GROUP_DETAILS_PANEL',
  ADMIN_CENTER_NEW_GROUP_PANEL = 'ADMIN_CENTER_NEW_GROUP_PANEL',
  ADMIN_CENTER_OPEN_ID = 'ADMIN_CENTER_OPEN_ID',
  APPLICATION_DETAILS_PANEL = 'APPLICATION_DETAILS_PANEL',
  APP_BAR_PANEL = 'APP_BAR_PANEL',
  APP_CENTER_ADD_FLOW_PANEL = 'APP_CENTER_ADD_FLOW_PANEL',
  APP_CENTER_ADD_SCHEMA_PANEL = 'APP_CENTER_ADD_SCHEMA_PANEL',
  APP_CENTER_APP_ROLES = 'APP_CENTER_APP_ROLES',
  APP_CENTER_AUTOMATE_PALETTE_INTEGRATION_PANEL = 'APP_CENTER_AUTOMATE_PALETTE_INTEGRATION_PANEL',
  APP_CENTER_BROWSE = 'APP_CENTER_BROWSE',
  APP_CENTER_CONFIG_FIELDS_FORM = 'APP_CENTER_CONFIG_FIELDS_FORM',
  APP_CENTER_CONTEXT_MENU_EXTENSIONS = 'APP_CENTER_CONTEXT_MENU_EXTENSIONS',
  APP_CENTER_DETAILS = 'APP_CENTER_DETAILS',
  APP_CENTER_EVENT_PANEL = 'APP_CENTER_EVENT_PANEL',
  APP_CENTER_EVENTS = 'APP_CENTER_EVENTS',
  APP_CENTER_FILTER = 'APP_CENTER_FILTER',
  APP_CENTER_NEW = 'APP_CENTER_NEW',
  AUDIT_LOGS_PANEL = 'AUDIT_LOGS_PANEL',
  CHANGELOG_PANEL = 'CHANGELOG_PANEL',
  CREATE_ORGANIZATION_PANEL = 'CREATE_ORGANIZATION_PANEL',
  CREATE_OR_UPDATE_TOKEN_PANEL = 'CREATE_OR_UPDATE_TOKEN_PANEL',
  CREATE_SCHEMA_PANEL = 'CREATE_SCHEMA_PANEL',
  DATA_CENTER = 'DATA_CENTER',
  DATA_CENTER_DATA_DETAILS_PANEL = 'DATA_CENTER_DATA_DETAILS_PANEL',
  DATA_CENTER_DATA_REGISTRY_INFO = 'DATA_CENTER_DATA_REGISTRY_INFO',
  DATA_CENTER_EDIT_METADATA_PANEL = 'DATA_CENTER_EDIT_METADATA_PANEL',
  DATA_CENTER_ENGINE_SELECTION = 'DATA_CENTER_ENGINE_SELECTION',
  DATA_CENTER_EXPORT_TDO_PANEL = 'DATA_CENTER_EXPORT_TDO_PANEL',
  DATA_CENTER_FILE_INFO_PANEL = 'DATA_CENTER_FILE_INFO_PANEL',
  DATA_CENTER_FOLDERS_AND_FILES = 'DATA_CENTER_FOLDERS_AND_FILES',
  DATA_CENTER_IMPORTER = 'DATA_CENTER_IMPORTER',
  DATA_CENTER_MDP_IFRAME_PANEL = 'DATA_CENTER_MDP_IFRAME_PANEL',
  DATA_CENTER_PROCESS_AI = 'DATA_CENTER_PROCESS_AI',
  DATA_CENTER_SCHEDULE_INFO_PANEL = 'DATA_CENTER_SCHEDULE_INFO_PANEL',
  DATA_CENTER_SELECT_IMPORT_SOURCE = 'DATA_CENTER_SELECT_IMPORT_SOURCE',
  DATA_CENTER_SOURCE_INFO_PANEL = 'DATA_CENTER_SOURCE_INFO_PANEL',
  DATA_CENTER_SDO_VIEWER = 'DATA_CENTER_SDO_VIEWER',
  DATA_LABELER = 'DATA_LABELER',
  EDIT_DATA_REGISTRY_PANEL = 'EDIT_DATA_REGISTRY_PANEL',
  EDIT_FILE_METADATA = 'EDIT_FILE_METADATA',
  EDIT_PROFILE_PICTURE = 'EDIT_PROFILE_PICTURE',
  EDIT_SCHEMA_PANEL = 'EDIT_SCHEMA_PANEL',
  ENGINE_CENTER_CUSTOM_FIELDS_FORM = 'ENGINE_CENTER_CUSTOM_FIELDS_FORM',
  ENGINE_CENTER_DETAILS = 'ENGINE_CENTER_DETAILS',
  ENGINE_CENTER_EDIT_RELEASE_NOTES = 'ENGINE_CENTER_EDIT_RELEASE_NOTES',
  ENGINE_CENTER_FILTER = 'ENGINE_CENTER_FILTER',
  ENGINE_CENTER_INPUT_TYPE_FLYOUT = 'ENGINE_CENTER_INPUT_TYPE_FLYOUT',
  ENGINE_CENTER_NEW = 'ENGINE_CENTER_NEW',
  ENGINE_CENTER_NEW_BUILD = 'ENGINE_CENTER_NEW_BUILD',
  ENGINE_CENTER_SCHEMAS_FORM_PANEL = 'ENGINE_CENTER_SCHEMAS_FORM_PANEL',
  ENGINE_CENTER_VERSION_HISTORY_PANEL = 'ENGINE_CENTER_VERSION_HISTORY_PANEL',
  EVENT_CENTER_SUBSCRIPTIONS = 'EVENT_CENTER_SUBSCRIPTIONS',
  FLOW_DETAILS_PANEL = 'FLOW_DETAILS_PANEL',
  FLOW_TEMPLATE_DETAILS_PANEL = 'FLOW_TEMPLATE_DETAILS_PANEL',
  GROUP_INFO_PANEL = 'GROUP_INFO_PANEL',
  HELP_PANEL = 'HELP_PANEL',
  INSTANCE_ADMIN_CENTER_BROWSE = 'INSTANCE_ADMIN_CENTER_BROWSE',
  INVITE_REQUESTS_PANEL = 'INVITE_REQUESTS_PANEL',
  INVITE_USERS_PANEL = 'INVITE_USERS_PANEL',
  JOBS_DETAILS_PANEL = 'JOBS_DETAILS_PANEL',
  JOBS_TABLE_SETTINGS_PANEL = 'JOBS_TABLE_SETTINGS_PANEL',
  MANAGE_GRANTS = 'MANAGE_GRANTS',
  MARKETPLACE = 'MARKETPLACE',
  MARKETPLACE_CART = 'MARKETPLACE_CART',
  MARKETPLACE_ITEM_DETAILS = 'MARKETPLACE_ITEM_DETAILS',
  NEW_PERMISSION_SET_PANEL = 'NEW_PERMISSION_SET_PANEL',
  NODE_DETAILS_PANEL = 'NODE_DETAILS_PANEL',
  NOTIFICATION_APPLICATION = 'NOTIFICATION_APPLICATION',
  NOTIFICATION_DETAIL = 'NOTIFICATION_DETAIL',
  NOTIFICATION_PANEL = 'NOTIFICATION_PANEL',
  NOTIFICATION_SETTING = 'NOTIFICATION_SETTING',
  NOTIFICATION_UTILITY = 'NOTIFICATION_UTILITY',
  ORGANIZATION_PANEL = 'ORGANIZATION_PANEL',
  PACKAGE_DETAILS_PANEL = 'PACKAGE_DETAILS_PANEL',
  PACKAGE_MANAGER_PANEL = 'PACKAGE_MANAGER_PANEL',
  PACKAGE_SETUP_PANEL = 'PACKAGE_SETUP_PANEL',
  PACKAGE_SETTINGS_PANEL = 'PACKAGE_SETTINGS_PANEL',
  PALETTE_DETAILS_PANEL = 'PALETTE_DETAILS_PANEL',
  PERMISSIONS_PANEL = 'PERMISSIONS_PANEL',
  PERMISSION_SET_DETAILS_PANEL = 'PERMISSION_SET_DETAILS_PANEL',
  PROCESSING_CENTER = 'PROCESSING_CENTER',
  PROCESSING_CENTER_ADVANCED_SETTINGS = 'PROCESSING_CENTER_ADVANCED_SETTINGS',
  PROCESSING_CENTER_AFFILIATE_PANEL = 'PROCESSING_CENTER_AFFILIATE_PANEL',
  PROCESSING_CENTER_BULK_PROVISIONING_PANEL = 'PROCESSING_CENTER_BULK_PROVISIONING_PANEL',
  PROCESSING_CENTER_DAG_BUILDER = 'PROCESSING_CENTER_DAG_BUILDER',
  PROCESSING_CENTER_DAG_DETAILS_PANEL = 'PROCESSING_CENTER_DAG_DETAILS_PANEL',
  PROCESSING_CENTER_ERROR_DETAIL = 'PROCESSING_CENTER_ERROR_DETAIL',
  PROCESSING_CENTER_INGESTION = 'PROCESSING_CENTER_INGESTION',
  PROCESSING_CENTER_JOBS = 'PROCESSING_CENTER_JOBS',
  PROCESSING_CENTER_SOURCES = 'PROCESSING_CENTER_SOURCES',
  RESOURCE_CENTER = 'RESOURCE_CENTER',
  REVIEW_ORG_INVITE_PANEL = 'REVIEW_ORG_INVITE_PANEL',
  SCHEMA_DETAILS_PANEL = 'SCHEMA_DETAILS_PANEL',
  SETTING_PREFERENCE = 'SETTING_PREFERENCE',
  SUPPORTED_FILE_TYPES = 'SUPPORTED_FILE_TYPES',
  TASK_DETAILS_PANEL = 'TASK_DETAILS_PANEL',
  TASKS_TABLE_SETTINGS_PANEL = 'TASKS_TABLE_SETTINGS_PANEL',
  TEST_COMPONENT = 'TEST_COMPONENT',
  TEST_COMPONENT2 = 'TEST_COMPONENT2',
  TEST_COMPONENT3 = 'TEST_COMPONENT3',
  USER_APPLICATION_SETTINGS_PANEL = 'USER_APPLICATION_SETTINGS_PANEL',
  USER_DETAILS_PANEL = 'USER_DETAILS_PANEL',
  VERSION_PANEL = 'VERSION_PANEL',
  UPSERT_INTEGRATION_PANEL = 'UPSERT_INTEGRATION_PANEL',
  USER_ACCESS_PANEL = 'USER_ACCESS_PANEL',
}

export type TRegistryKeys = AvailableComponents | AvailablePanels | AvailableWidgets;

export type TRegistryComponent<T> = FunctionComponent<T> | null;

export type TRegistry<T extends string = TRegistryKeys> = {
  [key in T]?: TRegistryComponent<unknown> | Promise<TRegistryComponent<unknown>>;
};

export type TRegistryLookup = (key: string) => Promise<TRegistryComponent<unknown>>;

export type TRegistryPromise = Promise<TRegistryComponent<unknown>>;

export type TSdkBootstrapOptions<T extends string = TRegistryKeys> = {
  registry?: TRegistry<T> | Promise<{ registry: TRegistry<T> }>;
  registryLookup?: TRegistryLookup | Promise<{ registryLookup: TRegistryLookup }>;
  configureClasses?: boolean;
};

export type TGraphQLPage<T> = {
  records: T[];
  count: number;
  offset?: number;
  limit?: number;
};

export enum EntityType {
  AiPackages = 'aiPackages',
  EngineCategories = 'engineCategories',
  Engines = 'engines',
  SourceTypes = 'sourceTypes',
  Folders = 'folders',
  Sources = 'sources',
  Schedules = 'schedules',
  Tdos = 'tdos',
  Jobs = 'jobs',
}

export enum SdkEvents {
  fileUpload = 'fileUpload',
  fileUploadProgress = 'fileUploadProgress',
  tdoCreated = 'tdoCreated',
  jobCreated = 'jobCreated',
  languageChange = 'languageChange',
  permissionChanged = 'permissionChanged',
  openSupport = 'openSupport',
  tokenUpdated = 'tokenUpdated',
}

export type SdkEventCallback = (error?: Error | null, ...args: unknown[]) => void;

export interface ISdkError extends Error {}

export interface ISdkEventManager {
  events: Map<SdkEvents, SdkEventCallback>;
  on: (eventName: SdkEvents, cb: SdkEventCallback) => void;
  off: (eventName: SdkEvents) => void;
  dispatch: (eventName: SdkEvents, error?: ISdkError | null, ...args: unknown[]) => void;
  createError: (message: string) => ISdkError;
}
